import moment from 'moment'
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword
} from 'firebase/auth'
import { getDatabase, ref, onValue, set } from 'firebase/database'

import firebaseApp from '../utils/firebase'
import triggerFunction from '../utils/serverless'

const auth = getAuth(firebaseApp)
const db = getDatabase(firebaseApp)

export const confirmRegistration = async (uid, token) => {
  try {
    const response = await triggerFunction(
      'preview/registration/confirmation',
      {
        params: {
          uid,
          token
        }
      }
    )

    return response
  } catch (error) {
    throw new Error(error)
  }
}

export const isLoggedIn = () =>
  new Promise(resolve => {
    onAuthStateChanged(auth, user => {
      if (user) {
        resolve(user.uid)
      } else {
        resolve(null)
      }
    })
  })

export const getUserProfile = uid =>
  new Promise((resolve, reject) => {
    try {
      const minersRef = ref(db, `registrations/${uid}`)

      onValue(minersRef, snapshot => {
        const data = snapshot.val()

        resolve(data)
      })
    } catch (error) {
      reject(error)
    }
  })

export const signUp = async email => {
  try {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      'h3ll0p00l'
    )

    return user
  } catch (error) {
    throw new Error(error)
  }
}

export const createUserProfile = async (
  { uid, email, isMiner, usesCleanEnergy },
  token
) => {
  try {
    const minersRef = ref(db, `registrations/${uid}`)

    const response = await set(minersRef, {
      email,
      isMiner,
      usesCleanEnergy,
      status: 'created',
      utcTimestamp: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
    })

    await confirmRegistration(uid, token)

    return response
  } catch (error) {
    throw new Error(error)
  }
}

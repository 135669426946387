/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext } from 'react'

import ThemeContext from '../../../contexts/ThemeContext'

import './styles.scss'

const Switch = () => {
  const themeData = useContext(ThemeContext)
  const { theme, toggleTheme } = themeData

  const handleKeyPress = event => {
    if (event.key !== 'Enter') return

    event.preventDefault()
    toggleTheme()
  }

  return (
    <>
      <input
        id='switch'
        type='checkbox'
        className='invisible-checkbox'
        checked={theme === 'dark'}
        onChange={toggleTheme}
      />
      <label
        tabIndex='0'
        htmlFor='switch'
        className='background'
        data-testid='switch-label'
        onKeyDown={event => handleKeyPress(event)}
      >
        <span className='toggle' />
      </label>
    </>
  )
}

export default Switch

import { useContext } from 'react'

import NavigationItem from '../NavigationItem'
import Switch from '../../Theme/Switch'

import AuthContext from '../../../contexts/AuthContext'

import '../NavigationItem/styles.scss'
import './styles.scss'

const NavigationItems = ({ clicked, email }) => {
  const authData = useContext(AuthContext)
  const { isAdmin } = authData

  return (
    <div className='navigation-items'>
      {isAdmin && (
        <NavigationItem link='/admin' clicked={clicked}>
          Admin
        </NavigationItem>
      )}

      <NavigationItem link='/' clicked={clicked}>
        Early access<em>!</em>
      </NavigationItem>

      <Switch />
    </div>
  )
}

export default NavigationItems

import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import AuthContext from '../contexts/AuthContext'

const RedirectRoute = () => {
  const history = useHistory()

  const authData = useContext(AuthContext)
  const { status } = authData

  switch (status) {
    case 'created':
      history.replace('/creation')
      break
    case 'confirmed':
      history.replace('/confirmation')
      break
    default:
      history.replace('/')
      break
  }

  return null
}

export default RedirectRoute

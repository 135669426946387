import './styles.scss'

const Copyright = ({ hasNoData }) => (
  <footer className={hasNoData ? 'floating' : null}>
    <p className='copyright'>
      © NoPool
      <br />
      <span className='fineprint'>
        NoPool is a trademark of{' '}
        <a href='https://joinmassive.com/' target='_blank' rel='noreferrer'>
          Massive Computing, Inc.
        </a>
      </span>
    </p>
  </footer>
)

export default Copyright

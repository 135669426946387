import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyDu9BkXW1-xTGIeW9q_6wKIVMWNL541yOU',
  authDomain: 'nopool-preview.firebaseapp.com',
  databaseURL: 'https://nopool-preview-default-rtdb.firebaseio.com',
  projectId: 'nopool-preview',
  storageBucket: 'nopool-preview.appspot.com',
  messagingSenderId: '1021821117342',
  appId: '1:1021821117342:web:9aece0c8d7589d4a976c8f',
  measurementId: 'G-NL4MHQF3EN'
}

const firebaseApp = initializeApp(firebaseConfig)

export default firebaseApp

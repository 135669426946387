import { useState } from 'react'
import { Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'

import Infobox from '../../components/Infobox'
import Captcha from '../../components/Captcha'
import MinerLayout from '../../components/Layouts/MinerLayout'

import './styles.scss'

import { signUp, createUserProfile } from '../../services/user'

const SignUp = () => {
  const [email, setEmail] = useState('')
  const [isMiner, setIsMiner] = useState(false)
  const [usesCleanEnergy, setUsesCleanEnergy] = useState(false)
  const [token, setToken] = useState(null)

  const handleSignUp = async event => {
    event.preventDefault()

    if (!token) {
      NotificationManager.error('You must verify that you’re human.')
      return
    }

    document.body.style.cursor = 'wait'

    try {
      const { uid } = await signUp(email)

      const profile = {
        uid,
        email,
        isMiner,
        usesCleanEnergy
      }

      await createUserProfile(profile, token)

      window.location = '/creation'
    } catch (error) {
      NotificationManager.error(
        error.message,
        'Something went wrong – please try again.'
      )

      const Sentry = await import('@sentry/react')
      Sentry.captureException(error)
    }

    document.body.style.cursor = 'default'
  }

  return (
    <MinerLayout>
      <Infobox />
      <div className='signup-container'>
        <form onSubmit={handleSignUp}>
          <input
            required
            type='email'
            placeholder='Your email address'
            name='email'
            id='email-signup'
            value={email}
            autoComplete='username'
            onChange={event => setEmail(event.target.value)}
          />
          <div className='checkbox'>
            <label htmlFor='checkbox-miner' className='label-container'>
              <input
                id='checkbox-miner'
                type='checkbox'
                onChange={event => setIsMiner(!isMiner)}
              />
              <span className='checkmark' />
              <span>
                Are you a miner<em>?</em>
              </span>
            </label>
          </div>
          <div className='checkbox'>
            <label htmlFor='checkbox-energy' className='label-container'>
              <input
                id='checkbox-energy'
                type='checkbox'
                onChange={event => setUsesCleanEnergy(!usesCleanEnergy)}
              />
              <span className='checkmark' />
              <span>
                If so, are you able to use clean energy<em>?</em>
              </span>
            </label>
          </div>
          <Captcha setToken={setToken} />
          <button type='submit' className='btn-submit'>
            Get early access
          </button>
        </form>
        <div className='privacy'>
          <span>
            We’ll email only about NoPool availability; see our{' '}
            <Link to='/privacy' target='_blank'>
              privacy policy
            </Link>{' '}
            for details
          </span>
        </div>
      </div>
    </MinerLayout>
  )
}

export default SignUp

import { NavLink } from 'react-router-dom'

import './styles.scss'

const NavigationItem = ({ link, clicked, children }) => (
  <div className='navigation-item'>
    <NavLink to={link} activeClassName='active' onClick={clicked}>
      {children}
    </NavLink>
  </div>
)

export default NavigationItem

import './styles.scss'

const Infobox = () => {
  return (
    <div className='infobox'>
      <p>
        <strong>NoPool</strong> is a Stratum mining pool with Kadena support and
        the goals of <strong>(1)</strong> no centralization,{' '}
        <strong>(2)</strong> no emission footprint or conventional fees for
        low-emission miners, and <strong>(3)</strong> no performance compromise.
      </p>
      <p>
        You can sign up to get access our feeless beta pool and apply for
        emission incentives or you can join our communities:
      </p>
      <p className='social'>
        <a href='https://blog.nopool.com/' target='_blank' rel='noreferrer'>
          <img src='images/substack.svg' alt='Substack' />
        </a>
        <a
          href='https://twitter.com/nopoolparty'
          target='_blank'
          rel='noreferrer'
        >
          <img src='images/twitter.svg' alt='Twitter' />
        </a>
        <a
          href='https://discord.com/invite/XWQn4xcJAE'
          target='_blank'
          rel='noreferrer'
        >
          <img src='images/discord.svg' alt='Discord' />
        </a>
        <a
          href='https://www.linkedin.com/company/joinmassive/'
          target='_blank'
          rel='noreferrer'
        >
          <img src='images/linkedin.svg' alt='LinkedIn' />
        </a>
        <a
          href='https://github.com/joinmassive'
          target='_blank'
          rel='noreferrer'
        >
          <img src='images/github.svg' alt='GitHub' />
        </a>
      </p>
    </div>
  )
}

export default Infobox
